import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { ThemeProvider } from "@edenlabllc/ehealth-components";

import "./global.css";
import store from "./store";
import theme from "./theme";
import Routes from "./Routes";
import env from "./env";
import Cookies from "js-cookie";

const AUTH_COOKIE_NAME = "authorization";

const App = () => {
  useEffect(() => {
    window.addEventListener("unload", () => Cookies.remove(AUTH_COOKIE_NAME));
    return () => {
      window.removeEventListener("unload", () =>
        Cookies.remove(AUTH_COOKIE_NAME)
      );
    };
  }, []);

  const RecaptchaMiddleware = ({ children }) => {
    if (env.REACT_APP_RECAPTCHA_ENABLED) {
      return (
        <GoogleReCaptchaProvider reCaptchaKey={env.REACT_APP_RECAPTCHA_KEY}>
          {children}
        </GoogleReCaptchaProvider>
      );
    }

    return children;
  };

  return (
    <>
      <Helmet titleTemplate="Електронна система охорони здоров'я eHealth — %s" />
      <RecaptchaMiddleware>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </Provider>
      </RecaptchaMiddleware>
    </>
  );
};

export default App;
